import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { storage, ClientId, RefreshTokenStorageKey, IsNullOrEmpty, ProfileStorageKey } from "../../Global"; // ,Queue
import Auth from '../../Auth';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import HeaderRun from './Header.run'
import EditPasswordModel from '../../EditPasswordModel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Header extends Component {


    constructor(props) {
        super(props);

        this.state = {
            // ShowMessageModal: false,
            Profile: {},//私聊消息对象
            ShowEditPasswordModel: false,
        };

        this.handleLogout = this.handleLogout.bind(this);
        this.handleEditPasswordToggle = this.handleEditPasswordToggle.bind(this);
        // this.handleMessageToggle = this.handleMessageToggle.bind(this); 
        // this.Queue = new Queue();//私聊消息队列
    }
    componentDidMount() {
        HeaderRun();
        // 加载用户信息菜单
        var profileStorage = storage.read(ProfileStorageKey);
        if (!IsNullOrEmpty(profileStorage)) {
            this.setState({
                Profile: profileStorage
            });
        }
    }

    // 修改密码
    handleEditPassword= e =>{ 
        e.preventDefault()  
        console.log(123123);     
        this.setState({ ShowEditPasswordModel: true });
    }
    // 关闭修改密码
    handleEditPasswordToggle(message, color) {
        this.setState({ ShowEditPasswordModel: false });// 隐藏弹窗
        if (!IsNullOrEmpty(message)) {
            toast(message, { type: color, position: 'bottom-right' });
        }
    }
    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }
    handleLogout(e) {
        let postData = {
            refreshToken: storage.read(RefreshTokenStorageKey)
        };
        axios.post(`/api/auth/Logout?clientid=${ClientId}&t=${new Date().getTime()}`, postData)
            .then(function () {
                Auth.signOut();
            }).catch(function (error) {
                console.log(error);
                this.setState({ Alert: { Visible: true, Message: '系统发生异常，请稍后再试', Color: "danger" } });
            }.bind(this));
    }
    render() {
        return (
            <header className="topnavbar-wrapper">
                <ToastContainer />
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo text-white">
                                {/* <img className="img-fluid" src="img/logo.png" alt="App Logo" /> */}
                                预约系统
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />

                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                        <li  className="nav-item">
                            
                        { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a title="修改密码" href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.handleEditPassword} hidden={IsNullOrEmpty(this.state.Profile.realName)}>
                                <em className="far fa-edit"></em>
                            </a>
                        </li>
                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        { /* START 登录帐号 */}
                        <li className="nav-item" hidden={IsNullOrEmpty(this.state.Profile.realName)}>
                            <span className="nav-link">{this.state.Profile.realName}({this.state.Profile.username})</span>
                        </li>
                        <li className="nav-item" hidden={!IsNullOrEmpty(this.state.Profile.realName)}>
                            <span className="nav-link">您好！游客</span>
                        </li>
                        { /* END 登录帐号 */}
                        { /* START 帐号余额 */}
                        {/* <li className="nav-item" hidden={IsNullOrEmpty(this.state.Profile.realName) || this.state.Profile.type === "管理员"}>
                            <span className="nav-link">{this.state.Profile.balance}</span>
                        </li> */}
                        { /* END 帐号余额 */}
                        { /* START Logout button */}
                        <li className="nav-item" hidden={IsNullOrEmpty(this.state.Profile.realName)}>
                            <a title="退出" className="nav-link" onClick={this.handleLogout.bind(this)}>
                                {/* <em className="icon-logout"></em> */}退出
                            </a>
                        </li>
                        { /* END Logout menu */}
                        { /* START login button */}
                        <li className="nav-item" hidden={!IsNullOrEmpty(this.state.Profile.realName)}>
                            <a title="登录" className="nav-link" href='/login'>
                                {/* <em className="icon-login"></em> */}登录
                            </a>
                        </li>
                        { /* END login menu */}
                    </ul>
                    { /* END Right Navbar */}

                </nav>
                { /* END Top Navbar */}
                <EditPasswordModel Username={this.state.Profile.username} Show={this.state.ShowEditPasswordModel} Toggle={this.handleEditPasswordToggle} />
                
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);