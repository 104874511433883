import React from 'react';
import { FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { ClientId, HasValidateError, QueryString, IsNullOrEmpty } from "./Global";
import axios from 'axios';
import StrapAlert from './StrapAlert';
import FormValidator from './components/Forms/FormValidator';
import SHA512 from 'sha512-es';
// https://www.npmjs.com/package/react-select
// http://jedwatson.github.io/react-select/

/**
 * @author: 李德龙
 * @param {Show} 是否显示弹窗
 * @param {Toggle} 通知父组件关闭弹窗
 * @example
 */
class EditPasswordModel extends React.Component {
    constructor(props, context) {
        super(props, context);
        var qs = QueryString();

        this.state = {
            Username: props.Username, // 用户名
            Show: props.Show, // 是否显示弹窗
            Toggle: props.Toggle, // 通知父组件关闭弹窗
            Oldword: '',
            Password: '',
            Alert: { Visible: false, Message: '', Color: "danger" }, // 警告信息
            Dirty: false, // 字段有更新才能点击保存按钮
            Submited: false // 防止重复提交
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForm = this.handleForm.bind(this);
    }
    handleForm = (e) => {
        if (e) e.preventDefault();
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const result = FormValidator.validate(e.target);
        this.setState({
            Dirty: true,
            [e.target.name]: value,
            errors: {
                ...this.state.errors,
                [e.target.name]: result
            }
        });
    }

    // 获得父组件传递的Id
    componentWillReceiveProps(nextProps) {
        this.setState({ Alert: { Visible: false, Message: '' } });
        this.setState({
            Username: nextProps.Username, // 用户名
            Show: nextProps.Show, // 是否显示弹窗
            Toggle: nextProps.Toggle, // 通知父组件关闭弹窗            
            Oldword: '',
            Password: ''
        });

    }

    toggleModal = () => {
        this.setState({ Show: !this.state.Show });
        if (this.state.Toggle) this.state.Toggle();
    }
    handleSubmit(e) {
        if (e) e.preventDefault();
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))
        const { errors, hasError } = FormValidator.bulkValidate(inputs)
        this.setState({ errors: errors });
        if (hasError) {
            console.log(111);
            console.log(errors);
            console.log(hasError);
            return;
        }
        // 不允许重复提交
        if (this.state.Submited) {
            this.setState({ Alert: { Visible: true, Message: '请勿重复提交', Color: "danger" } });
            return;
        }
        this.setState({ Submited: true, Alert: { Visible: false, Message: '', Color: "danger" } });

        // alert(this.state.Enabled);
        let postData = `username=${this.state.Username}&oldword=${SHA512.hash(this.state.Oldword)}&password=${SHA512.hash(this.state.Password)}`;        
        let postUrl = `api/admin/profile/updatePassword?clientid=${ClientId}&t=${new Date().getTime()}`;
        axios.post(postUrl, postData)
            .then(function (response) {
                let res = response.data;
                console.log(res);
                if (res.r === '1') {
                    this.setState({ Alert: { Visible: true, Message: res.m, Color: "success" } });
                    if (this.state.Toggle) this.state.Toggle(res.m, "success");
                }
                else {
                    this.setState({ Alert: { Visible: true, Message: res.m, Color: "danger" } });
                }
                this.setState({ Submited: false }); // 保存按钮可以再次点击
            }.bind(this))
            .catch(function (error) {
                this.setState({ Submited: false }); // 保存按钮可以再次点击
                console.log(error);
                this.setState({ Alert: { Visible: true, Message: '系统发生异常，请稍后再试', Color: "danger" } });
            }.bind(this));
    }
    render() {

        return (
            <Modal isOpen={this.state.Show} toggle={this.toggleModal} >
                <form method="post" id="ProfilePasswordModel" name="ProfilePasswordModel" onSubmit={this.handleSubmit}>
                    <ModalHeader toggle={this.toggleModal}>修改密码</ModalHeader>
                    <ModalBody>
                        {/* <ToastContainer /> */}
                        <StrapAlert Alert={this.state.Alert} />


                        <fieldset>
                            <FormGroup row>
                                <label htmlFor="Oldword" className="col-md-3 col-form-label text-muted">旧密码 <span className="text-danger">*</span></label>
                                <div className="col-md-9">
                                    <Input id="Oldword" name="Oldword" ref="Oldword" type="text" className="form-control form-control"
                                        placeholder="请输入旧密码"
                                        invalid={HasValidateError(this.state.errors, 'Oldword', 'required') || HasValidateError(this.state.errors, 'Oldword', 'minlen')}
                                        onChange={this.handleForm}
                                        data-validate='["required", "minlen"]'
                                        data-param="6"
                                        defaultValue={this.state.Oldword}
                                    />
                                    {HasValidateError(this.state.errors, 'Oldword', 'required') && <span className="invalid-feedback">旧密码不能为空</span>}
                                    {HasValidateError(this.state.errors, 'Oldword', 'minlen') && <span className="invalid-feedback">密码位数不可少于6位</span>}
                                </div>
                            </FormGroup>
                        </fieldset>

                        <fieldset>
                            <FormGroup row>
                                <label htmlFor="password" className="col-md-3 col-form-label text-muted">新密码 <span className="text-danger">*</span></label>
                                <div className="col-md-9">
                                    <Input id="Password" name="Password" ref="Password" type="text" className="form-control form-control"
                                        placeholder="请输入新密码"
                                        invalid={HasValidateError(this.state.errors, 'Password', 'required') || HasValidateError(this.state.errors, 'Password', 'minlen')}
                                        onChange={this.handleForm}
                                        data-validate='["required", "minlen"]'
                                        data-param="6"
                                        defaultValue={this.state.Password}
                                    />
                                    {HasValidateError(this.state.errors, 'Password', 'required') && <span className="invalid-feedback">新密码不能为空</span>}
                                    {HasValidateError(this.state.errors, 'Password', 'minlen') && <span className="invalid-feedback">密码位数不可少于6位</span>}
                                </div>
                            </FormGroup>
                        </fieldset>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" onSubmit={this.handleSubmit}>确定</Button>
                        <Button color="secondary" onClick={this.toggleModal}>取消</Button>
                    </ModalFooter>
                </form>
            </Modal>

        );
    }
}

export default EditPasswordModel;