import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;
{/* 首页 */}
const Loading = lazy(() => import('./Loading'));

{/* -----------------管理员路由----------------- */}
{/* 首页 */}
const Consoles = lazy(() => import('./admin/Consoles'));
{/* 登录 */}
const Login = lazy(() => import('./Login'));

{/* 用户管理 */}
{/* 账号信息 */}
const Profiles = lazy(() => import('./admin/Profiles'));
{/* 批量添加用户 */}
const ProfileBulk = lazy(() => import('./admin/ProfileBulk'));
{/* 用户标签 */}
const Labels = lazy(() => import('./admin/Labels'));
{/* 用户等级 */}
const Grades = lazy(() => import('./admin/Grades'));
{/* 违约记录 */}
const UserBreachs = lazy(() => import('./admin/UserBreachs'));
{/* 帐号申请 */}
const ApplyProfiles = lazy(() => import('./admin/ApplyProfiles'));
{/* 帐号申请详细 */}
const ApplyProfileDetail = lazy(() => import('./admin/ApplyProfileDetail'));

{/* 仪器管理 */}
{/* 仪器 */}
const Devices = lazy(() => import('./admin/Devices'));
{/* 仪器编辑 */}
const DeviceEdit = lazy(() => import('./admin/DeviceEdit'));
{/* 仪器详细 */}
const DeviceDetail = lazy(() => import('./admin/DeviceDetail'));
{/* 仪器机时、包时预约价格 */}
const DevicePrices = lazy(() => import('./admin/DevicePrices'));
{/* 仪器送样预约价格 */}
const DeviceSamplePrices = lazy(() => import('./admin/DeviceSamplePrices'));
{/* 仪器维护 */}
const DeviceStopTimes = lazy(() => import('./admin/DeviceStopTimes'));
{/* 批量维护 */}
const DeviceMaintenance = lazy(() => import('./admin/DeviceMaintenance'));
{/* 选择仪器 */}
const AppointmentDevices = lazy(() => import('./admin/AppointmentDevices'));
{/* 仪器预约 */}
const Appointment = lazy(() => import('./admin/Appointment'));
{/* 补充预约 */}
const ManagerAppointmentDevices = lazy(() => import('./admin/ManagerAppointmentDevices'));
{/* 补充预约 */}
const ManagerAppointment = lazy(() => import('./admin/ManagerAppointment'));
{/* 补充预约 */}
const ManagerAppointments = lazy(() => import('./admin/ManagerAppointments'));
{/* 机时查询 */}
const DeviceTimes = lazy(() => import('./admin/DeviceTimes'));
{/* 图表 */}
const DevicePackeTimes = lazy(() => import('./admin/DevicePackeTimes'));
{/* 统计 */}
const DeviceStatisticsTimes = lazy(() => import('./admin/DeviceStatisticsTimes'));
{/* 统计 */}
const DeviceProfiles = lazy(() => import('./admin/DeviceProfiles'));
{/* 仪器机时规则 */}
const DeviceRules = lazy(() => import('./admin/DeviceRules'));
{/* 包时用户权限 */}
const PackageDeviceProfiles = lazy(() => import('./admin/PackageDeviceProfiles'));
{/* 仪器预约类型规则 */}
const DeviceTypeRules = lazy(() => import('./admin/DeviceTypeRules'));



{/* 管理员管理 */}
{/* 管理员管理 */}
const Managers = lazy(() => import('./admin/Managers'));
{/* 角色管理 */}
const Roles = lazy(() => import('./admin/Roles'));
{/* 新增修改 */}
const Role = lazy(() => import('./admin/Role'));
{/* 角色管理 */}
const DeviceFreeRoles = lazy(() => import('./admin/DeviceFreeRoles'));
{/* 新增修改 */}
const DeviceFreeRole = lazy(() => import('./admin/DeviceFreeRole'));
{/* 角色管理 */}
const DeviceFreeUsers = lazy(() => import('./admin/DeviceFreeUsers'));
{/* 新增修改 */}
const DeviceFreeUser = lazy(() => import('./admin/DeviceFreeUser'));
{/* 角色分类 */}
const RoleCategories = lazy(() => import('./admin/RoleCategories'));


{/* 财务管理 */}
{/* 财务 */}
const Capital1s = lazy(() => import('./admin/Capitals'));
{/* 财务明细 */}
const CapitalDetail1s = lazy(() => import('./admin/CapitalDetails'));

{/* 财务 */}
const Capitals = lazy(() => import('./admin/CapitalNews'));
{/* 财务明细 */}
const CapitalDetails = lazy(() => import('./admin/CapitalNewDetails'));
{/* 费用统计 */}
const ExpenseDetails = lazy(() => import('./admin/ExpenseDetails'));
{/* 费用统计 */}
const OrderEndDetails = lazy(() => import('./admin/OrderEndDetails'));


{/* 预约管理 */}
{/* 预约管理 */}
const Orders = lazy(() => import('./admin/Orders'));
{/* 机时预约详情 */}
const OrderDetail = lazy(() => import('./admin/OrderDetail'));
{/* 结算订单 */}
const OrderSettlement = lazy(() => import('./admin/OrderSettlement'));
{/* 预约查询 */}
const OrderSeach = lazy(() => import('./admin/OrderSeach'));
{/* 结算终审 */}
const EndOrders = lazy(() => import('./admin/EndOrders'));
{/* 送样申请列表 */}
const ApplySamples = lazy(() => import('./admin/ApplySamples'));
{/* 送样申请详细 */}
const ApplySampleDetail = lazy(() => import('./admin/ApplySampleDetail'));
{/* 送样下单 */}
const ApplySample = lazy(() => import('./admin/ApplySample'));
{/* 包时申请列表 */}
const ApplyPackages = lazy(() => import('./admin/ApplyPackages'));
{/* 包时申请详细 */}
const ApplyPackageDetail = lazy(() => import('./admin/ApplyPackageDetail'));
{/* 包时下单 */}
const ApplyPackage = lazy(() => import('./admin/ApplyPackage'));
{/* 培训计划 */}
const Plans = lazy(() => import('./admin/Plans'));
{/* 培训报名管理 */}
const PlanMembers = lazy(() => import('./admin/PlanMembers'));
{/* 培训报名详细 */}
const PlanMemberDetail = lazy(() => import('./admin/PlanMemberDetail'));
{/* 培训报名下单 */}
const ApplyPlan = lazy(() => import('./admin/ApplyPlan'));
{/* 结算撤销 */}
const CancelOrders = lazy(() => import('./admin/CancelOrders'));
{/* 使用记录 */}
const UsageRecords = lazy(() => import('./admin/UsageRecords'));


{/* 系统配置 */}
{/* 系统配置 */}
const Setting = lazy(() => import('./admin/Setting'));
{/* 项目来源 */}
const Sources = lazy(() => import('./admin/Sources'));
{/* 禁用机时 */}
const OrderStopDates = lazy(() => import('./admin/OrderStopDates'));
{/* 等级权限 */}
const GradePrivileges = lazy(() => import('./admin/GradePrivileges'));
{/* 显示设置 */}
const ShowSetting = lazy(() => import('./admin/ShowSetting'));

{/* 公告 */}
{/* 公告 */}
const Notices = lazy(() => import('./admin/Notices'));
{/* 新增修改 */}
const NoticeEdit = lazy(() => import('./admin/NoticeEdit'));
{/* 公告详细 */}
const NoticeDetail = lazy(() => import('./admin/NoticeDetail'));

{/* 更新日志 */}
{/* 更新日志管理 */}
const UpdateLogs = lazy(() => import('./admin/UpdateLogs'));
{/* 统计分析 */}
{/* 统计分析 */}
const ChartTimes = lazy(() => import('./admin/ChartTimes'));
{/* 分析结果 */}
const CreateChart = lazy(() => import('./admin/CreateChart'));
{/* 仪器上报信息 */}
const Sjsbyqsbs = lazy(() => import('./admin/Sjsbyqsbs'));
{/* 使用记录上报信息 */}
const Sjsbsysjs = lazy(() => import('./admin/Sjsbsysjs'));
const SjsbsysjDetails = lazy(() => import('./admin/SjsbsysjDetails'));
{/* 门禁功能 */}
const BindCards = lazy(() => import('./admin/BindCards'));
const FaceCheckTasks = lazy(() => import('./admin/FaceCheckTasks'));


{/* -----------------会员路由----------------- */}
{/* 预约详情 */}
const UserConsoles = lazy(() => import('./user/Consoles'));
{/* 仪器介绍 */}
const UserDevices = lazy(() => import('./user/Devices'));
{/* 帐号申请 */}
const UserApplyProfile = lazy(() => import('./user/ApplyProfile'));
{/* 帐号申请详细 */}
const UserApplyProfileDetail = lazy(() => import('./user/ApplyProfileDetail'));
{/* 仪器预约情况 */}
const PackeTimeDevice = lazy(() => import('./user/PackeTimeDevice'));
{/* 仪器预约情况 */}
// const PackeTimeDevice1 = lazy(() => import('./user/PackeTimeDevice1'));
{/* 包时预约 */}
const UserAppointmentDevices = lazy(() => import('./user/AppointmentDevices'));
{/* 送样预约 */}
const UserApplySampleDevices = lazy(() => import('./user/ApplySampleDevices'));
{/* 包时预约 */}
const UserApplyPackageDevices = lazy(() => import('./user/ApplyPackageDevices'));
{/* 送样申请 */}
const UserApplySample = lazy(() => import('./user/ApplySample'));
{/* 送样申请列表 */}
const UserApplySamples = lazy(() => import('./user/ApplySamples'));
{/* 送样申请详细 */}
const UserApplySampleDetail = lazy(() => import('./user/ApplySampleDetail'));
{/* 包时申请 */}
const UserApplyPackage = lazy(() => import('./user/ApplyPackage'));
{/* 包时申请列表 */}
const UserApplyPackages = lazy(() => import('./user/ApplyPackages'));
{/* 包时申请详细 */}
const UserApplyPackageDetail = lazy(() => import('./user/ApplyPackageDetail'));
{/* 机时预约 */}
const UserAppointment = lazy(() => import('./user/Appointment'));
{/* 机时预约 */}
const UserOrders = lazy(() => import('./user/Orders'));
{/* 机时预约详情 */}
const UserOrderDetail = lazy(() => import('./user/OrderDetail'));
{/* 送样预约 */}
const UserSampleOrders = lazy(() => import('./user/SampleOrders'));
{/* 送样预约详情 */}
const UserSampleOrderDetail = lazy(() => import('./user/SampleOrderDetail'));
{/* 公告 */}
const UserNotices = lazy(() => import('./user/Notices'));
{/* 公告详细 */}
const UserNoticeDetail = lazy(() => import('./user/NoticeDetail'));
{/* 仪器详细 */}
const UserDeviceDetail = lazy(() => import('./user/DeviceDetail'));
{/* 违约记录 */}
const UserUserBreachs = lazy(() => import('./user/UserBreachs'));
{/* 费用统计 */}
const UserUserExport = lazy(() => import('./user/UserExport'));
{/* 费用统计 */}
const UserExpenseDetails = lazy(() => import('./user/ExpenseDetails'));
{/* 培训计划 */}
const UserPlans = lazy(() => import('./user/Plans'));
{/* 培训报名表单 */}
const UserPlanMember = lazy(() => import('./user/PlanMember'));
{/* 培训报名详细 */}
const UserPlanMemberDetail = lazy(() => import('./user/PlanMemberDetail'));
{/* 培训报名管理 */}
const UserPlanMembers = lazy(() => import('./user/PlanMembers'));
{/* 帐号管理 */}
const UserProfiles = lazy(() => import('./user/Profiles'));
{/* 帐号管理 */}
const UserEndProfiles = lazy(() => import('./user/EndProfiles'));
{/* 统计分析 */}
{/* 统计分析 */}
const UserChartTimes = lazy(() => import('./user/ChartTimes'));
{/* 分析结果 */}
const UserCreateChart = lazy(() => import('./user/CreateChart'));
{/* 测试 */}
const Test = lazy(() => import('./user/Test'));

const ChartFlot = lazy(() => import('./components/Charts/ChartFlot'));
const ChartRadial = lazy(() => import('./components/Charts/ChartRadial'));
const ChartChartJS = lazy(() => import('./components/Charts/ChartChartJS'));
const ChartMorris = lazy(() => import('./components/Charts/ChartMorris'));
const ChartChartist = lazy(() => import('./components/Charts/ChartChartist'));
const FontIcons = lazy(() => import('./components/Elements/FontIcons'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/register',
    '/recover',
    '/lock',
    '/notfound',
    '/error500',
    '/maintenance'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)}/>
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/loading" component={waitFor(Loading)} />
                                    
                                    {/* 模版路由 */}
                                     {/*Charts*/}
                                     <Route path="/chart-flot" component={waitFor(ChartFlot)} />
                                    <Route path="/chart-radial" component={waitFor(ChartRadial)} />
                                    <Route path="/chart-chartjs" component={waitFor(ChartChartJS)} />
                                    <Route path="/chart-morris" component={waitFor(ChartMorris)} />
                                    <Route path="/chart-chartist" component={waitFor(ChartChartist)} />
                                    <Route path="/icons-font" component={waitFor(FontIcons)} />
                                    {/* -----------------管理员路由----------------- */}
                                    <Route path="/admin/Consoles" component={waitFor(Consoles)} />
                                    <Route path="/admin/Profiles" component={waitFor(Profiles)} />
                                    <Route path="/admin/ApplyProfiles" component={waitFor(ApplyProfiles)} />
                                    <Route path="/admin/ApplyProfileDetail" component={waitFor(ApplyProfileDetail)} />
                                    <Route path="/admin/ProfileBulk" component={waitFor(ProfileBulk)} />
                                    <Route path="/admin/Labels" component={waitFor(Labels)} />
                                    <Route path="/admin/Grades" component={waitFor(Grades)} />
                                    <Route path="/admin/Devices" component={waitFor(Devices)} />
                                    <Route path="/admin/DeviceEdit" component={waitFor(DeviceEdit)} />
                                    <Route path="/admin/DeviceDetail" component={waitFor(DeviceDetail)} />
                                    <Route path="/admin/DevicePrices" component={waitFor(DevicePrices)} />
                                    <Route path="/admin/DeviceSamplePrices" component={waitFor(DeviceSamplePrices)} />
                                    <Route path="/admin/DeviceStopTimes" component={waitFor(DeviceStopTimes)} />
                                    <Route path="/admin/DeviceMaintenance" component={waitFor(DeviceMaintenance)} />
                                    <Route path="/admin/DeviceTimes" component={waitFor(DeviceTimes)} />
                                    <Route path="/admin/DevicePackeTimes" component={waitFor(DevicePackeTimes)} />
                                    <Route path="/admin/DeviceStatisticsTimes" component={waitFor(DeviceStatisticsTimes)} />
                                    <Route path="/admin/DeviceProfiles" component={waitFor(DeviceProfiles)} />
                                    <Route path="/admin/DeviceRules" component={waitFor(DeviceRules)} />
                                    <Route path="/admin/PackageDeviceProfiles" component={waitFor(PackageDeviceProfiles)} />
                                    <Route path="/admin/DeviceTypeRules" component={waitFor(DeviceTypeRules)} />
                                    <Route path="/admin/Capitals" component={waitFor(Capitals)} />
                                    <Route path="/admin/CapitalDetails" component={waitFor(CapitalDetails)} />
                                    <Route path="/admin/NewCapitals" component={waitFor(Capital1s)} />
                                    <Route path="/admin/NewCapitalDetails" component={waitFor(CapitalDetail1s)} />
                                    <Route path="/admin/Setting" component={waitFor(Setting)} />
                                    <Route path="/admin/OrderStopDates" component={waitFor(OrderStopDates)} />
                                    <Route path="/admin/GradePrivileges" component={waitFor(GradePrivileges)} />
                                    <Route path="/admin/ShowSetting" component={waitFor(ShowSetting)} />
                                    <Route path="/admin/Sources" component={waitFor(Sources)} />
                                    <Route path="/admin/Managers" component={waitFor(Managers)} />
                                    <Route path="/admin/ApplySample" component={waitFor(ApplySample)} />
                                    <Route path="/admin/ApplySamples" component={waitFor(ApplySamples)} />
                                    <Route path="/admin/ApplySampleDetail" component={waitFor(ApplySampleDetail)} />
                                    <Route path="/admin/ApplyPackage" component={waitFor(ApplyPackage)} />
                                    <Route path="/admin/ApplyPackages" component={waitFor(ApplyPackages)} />
                                    <Route path="/admin/ApplyPackageDetail" component={waitFor(ApplyPackageDetail)} />
                                    <Route path="/admin/Orders" component={waitFor(Orders)} />
                                    <Route path="/admin/OrderSeach" component={waitFor(OrderSeach)} />
                                    <Route path="/admin/EndOrders" component={waitFor(EndOrders)} />
                                    <Route path="/admin/OrderDetail" component={waitFor(OrderDetail)} />
                                    <Route path="/admin/OrderSettlement" component={waitFor(OrderSettlement)} />
                                    <Route path="/admin/Roles" component={waitFor(Roles)} />
                                    <Route path="/admin/Role" component={waitFor(Role)} />
                                    <Route path="/admin/DeviceFreeRoles" component={waitFor(DeviceFreeRoles)} />
                                    <Route path="/admin/DeviceFreeRole" component={waitFor(DeviceFreeRole)} />
                                    <Route path="/admin/DeviceFreeUsers" component={waitFor(DeviceFreeUsers)} />
                                    <Route path="/admin/DeviceFreeUser" component={waitFor(DeviceFreeUser)} />
                                    <Route path="/admin/RoleCategories" component={waitFor(RoleCategories)} />
                                    <Route path="/admin/Notices" component={waitFor(Notices)} />
                                    <Route path="/admin/NoticeEdit" component={waitFor(NoticeEdit)} />
                                    <Route path="/admin/NoticeDetail" component={waitFor(NoticeDetail)} />
                                    <Route path="/admin/UpdateLogs" component={waitFor(UpdateLogs)} />

                                    <Route path="/admin/UserBreachs" component={waitFor(UserBreachs)} />
                                    <Route path="/admin/AppointmentDevices" component={waitFor(AppointmentDevices)} />
                                    <Route path="/admin/Appointment" component={waitFor(Appointment)} />
                                    <Route path="/admin/ManagerAppointmentDevices" component={waitFor(ManagerAppointmentDevices)} />
                                    <Route path="/admin/ManagerAppointment" component={waitFor(ManagerAppointment)} />
                                    <Route path="/admin/ManagerAppointments" component={waitFor(ManagerAppointments)} />
                                    <Route path="/admin/Plans" component={waitFor(Plans)} />
                                    <Route path="/admin/ApplyPlan" component={waitFor(ApplyPlan)} />
                                    <Route path="/admin/PlanMemberDetail" component={waitFor(PlanMemberDetail)} />
                                    <Route path="/admin/PlanMembers" component={waitFor(PlanMembers)} />
                                    <Route path="/admin/ExpenseDetails" component={waitFor(ExpenseDetails)} />
                                    <Route path="/admin/OrderEndDetails" component={waitFor(OrderEndDetails)} />
                                    <Route path="/admin/CancelOrders" component={waitFor(CancelOrders)} />
                                    <Route path="/admin/UsageRecords" component={waitFor(UsageRecords)} />

                                    <Route path="/admin/ChartTimes" component={waitFor(ChartTimes)} />
                                    <Route path="/admin/CreateChart" component={waitFor(CreateChart)} />
                                    <Route path="/admin/Sjsbyqsbs" component={waitFor(Sjsbyqsbs)} />
                                    <Route path="/admin/Sjsbsysjs" component={waitFor(Sjsbsysjs)} />
                                    <Route path="/admin/SjsbsysjDetails" component={waitFor(SjsbsysjDetails)} />
                                    <Route path="/admin/BindCards" component={waitFor(BindCards)} />
                                    <Route path="/admin/FaceCheckTasks" component={waitFor(FaceCheckTasks)} />
                                    {/* -----------------会员路由----------------- */}
                                    <Route path="/user/Consoles" component={waitFor(UserConsoles)} />
                                    <Route path="/user/Devices" component={waitFor(UserDevices)} />
                                    <Route path="/user/ApplyProfile" component={waitFor(UserApplyProfile)} />
                                    <Route path="/user/ApplyProfileDetail" component={waitFor(UserApplyProfileDetail)} />
                                    <Route path="/user/PackeTimeDevice" component={waitFor(PackeTimeDevice)} />
                                    {/* <Route path="/user/PackeTimeDevice1" component={waitFor(PackeTimeDevice1)} /> */}
                                    <Route path="/user/AppointmentDevices" component={waitFor(UserAppointmentDevices)} />
                                    <Route path="/user/ApplySampleDevices" component={waitFor(UserApplySampleDevices)} />
                                    <Route path="/user/ApplyPackageDevices" component={waitFor(UserApplyPackageDevices)} />
                                    <Route path="/user/Appointment" component={waitFor(UserAppointment)} />
                                    <Route path="/user/ApplySample" component={waitFor(UserApplySample)} />
                                    <Route path="/user/ApplySamples" component={waitFor(UserApplySamples)} />
                                    <Route path="/user/ApplySampleDetail" component={waitFor(UserApplySampleDetail)} />
                                    <Route path="/user/ApplyPackage" component={waitFor(UserApplyPackage)} />
                                    <Route path="/user/ApplyPackages" component={waitFor(UserApplyPackages)} />
                                    <Route path="/user/ApplyPackageDetail" component={waitFor(UserApplyPackageDetail)} />
                                    <Route path="/user/Orders" component={waitFor(UserOrders)} />
                                    <Route path="/user/OrderDetail" component={waitFor(UserOrderDetail)} />
                                    <Route path="/user/SampleOrders" component={waitFor(UserSampleOrders)} />
                                    <Route path="/user/SampleOrderDetail" component={waitFor(UserSampleOrderDetail)} />
                                    <Route path="/user/Notices" component={waitFor(UserNotices)} />
                                    <Route path="/user/NoticeDetail" component={waitFor(UserNoticeDetail)} />
                                    <Route path="/user/DeviceDetail" component={waitFor(UserDeviceDetail)} />
                                    <Route path="/user/UserBreachs" component={waitFor(UserUserBreachs)} />
                                    <Route path="/user/UserExport" component={waitFor(UserUserExport)} />
                                    <Route path="/user/ExpenseDetails" component={waitFor(UserExpenseDetails)} />
                                    <Route path="/user/Plans" component={waitFor(UserPlans)} />
                                    <Route path="/user/PlanMember" component={waitFor(UserPlanMember)} />
                                    <Route path="/user/PlanMemberDetail" component={waitFor(UserPlanMemberDetail)} />
                                    <Route path="/user/PlanMembers" component={waitFor(UserPlanMembers)} />
                                    <Route path="/user/Profiles" component={waitFor(UserProfiles)} />
                                    <Route path="/user/EndProfiles" component={waitFor(UserEndProfiles)} />
                                    <Route path="/user/ChartTimes" component={waitFor(UserChartTimes)} />
                                    <Route path="/user/CreateChart" component={waitFor(UserCreateChart)} />
                                    <Route path="/Test" component={waitFor(Test)} />
                                    <Redirect to="/loading" />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);