import React, { Component } from 'react';

class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>Copyright&nbsp;&copy;&nbsp;2020&nbsp;-&nbsp;{year} &nbsp;&nbsp; 武汉智返宝科技有限公司 &nbsp;<a href="https://beian.miit.gov.cn">鄂ICP备18020978号-4</a> </span>
            </footer>
        );
    }

}

export default Footer;
