import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

/**
 * @author: 宋涛
 * 
 * 显示提示信息
 * 
 * @param {Alert} 提示信息
 *
 * @example
 * import StrapAlert from '../StrapAlert';
 * 
 * <StrapAlert Alert={this.state.Alert} /> * 
 */
class StrapAlert extends React.Component {

    constructor(props, context) {
        super(props, context);
        
        this.state = {
            Alert:props.Alert // 提示信息
        };
    }

    // 获得父组件传递的提示信息
    componentWillReceiveProps(nextProps) {
        this.setState({
            Alert:nextProps.Alert // 提示信息
        });
    }

    render() {       
        if (!this.state.Alert) {
            return null;
        }

        const onAlertDismiss = () => {
            let alert = this.state.Alert;
            alert.Visible = false;
            this.setState({ Alert:alert });
        }
        let color=this.state.Alert?((!this.state.Alert.Color || this.state.Alert.Color.trim()==='')?'danger':this.state.Alert.Color):'danger';
        let isOpen=this.state.Alert && this.state.Alert.Visible;

        return (
            <Alert color={color} isOpen={isOpen} toggle={onAlertDismiss}>
                <em className="fa fa-exclamation-circle fa-lg fa-fw"></em>
                <span dangerouslySetInnerHTML = {{ __html:this.state.Alert?this.state.Alert.Message:'' }}></span>
            </Alert>
        );
    }
}

StrapAlert.propType = {
    /** 提示信息 */
    Alert: PropTypes.object.isRequired
}

export default StrapAlert;
